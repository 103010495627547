<template>
  <div id="manage-product-list" class="row">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :opacity="0.97" :z-index="2147480000"></loading>
    <modal name="user-modal" class="self-user-modal" :width="'90%'" :height="'90%'">
      <div v-if="modalUser">
        <div class="modal-header fx-main fx-end pd-15-px">
          <i class="clickable fa fa-times" aria-hidden="true" v-on:click="hideModal"></i>
        </div>
        <div class="fx-main pd-2-em">
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Razão Social</label>
            <span>{{ modalUser.name }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Nome Fantasia</label>
            <span>{{ modalUser.company_name }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Razão Social</label>
            <span>{{ modalUser.trading_name }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>CNPJ</label>
            <span>{{ modalUser.cnpj }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>CPF</label>
            <span>{{ modalUser.cpf }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Email</label>
            <span>{{ modalUser.email }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Celular</label>
            <span>{{ modalUser.phone }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Inscrição Estadual</label>
            <span>{{ modalUser.ie }}</span>
          </div>
          <div v-if="modalUser.business_address" class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>CEP</label>
            <span>{{ modalUser.business_address.zipcode }}</span>
          </div>
          <div v-if="modalUser.business_address" class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Estado</label>
            <span>{{ modalUser.business_address.state }}</span>
          </div>
          <div v-if="modalUser.business_address" class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Cidade</label>
            <span>{{ modalUser.business_address.city }}</span>
          </div>
          <div v-if="modalUser.business_address" class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Endereço</label>
            <span>{{ modalUser.business_address.address }}</span>
          </div>
          <div v-if="modalUser.business_address" class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>N°</label>
            <span>{{ modalUser.business_address.number }}</span>
          </div>
          <div v-if="modalUser.business_address" class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Complemento</label>
            <span>{{ modalUser.business_address.complement }}</span>
          </div>
          <!-- <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Status</label>
            <span v-if="modalUser.approved==null">Em análise</span>
            <span v-if="modalUser.approved==true">Ativo</span>
            <span v-if="modalUser.approved==false">Bloqueado</span>
          </div> -->
          <!-- <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Tipo de Usuário</label>
            <span>{{ modalUser.type | toType }}</span>
          </div> -->
          <!-- <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Tier do Usuário</label>
            <span>{{ modalUser.tier | toTier }}</span>
          </div> -->
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>Cadastrado em</label>
            <span>{{ modalUser.created_at }}</span>
          </div>
          <div class="input-group fx-main mg-bot-2em col-md-6  ">
            <label>#</label>
            <span>{{ modalUser.id }}</span>
          </div>
          <div
            v-if="modalUser.update_proposal || swap"
            class="input-group fx-main mg-bot-2em col-md-6  "
          >
            <label>Alteração Cadastral</label>
            <button @click="swaper(false)" v-if="swap">Visualizar Hoje</button>
            <button @click="swaper(true)" v-else>Visualizar Alterações</button>
            <button @click="cadastral(true)">Aceitar</button>
            <button @click="cadastral(false)">Recusar</button>
          </div>
        </div>
      </div>
    </modal>

      <div class="wd-100 g-t-c-1 container-infos" ref="plistRef">
      <div
        v-for="user in userList"
        :key="user.id"
        class="fx-main manage-product-item default-border fx-a-i-cent shadow pd-2-em"
      >

        <!-- <div class="manage-product-name name-bold pd-15-px data-fs">

            <span class="product-name uppercase"><b>Tipo: </b></span><br>
            <span class="product-name uppercase">{{ user.type | toType }}</span>

        </div> -->
        <div class="name-bold pd-15-px">
            <span class="product-name uppercase"><b>E-mail: </b></span><br>
            <span class="product-name uppercase">{{ user.email }}</span>
        </div>
        <!--
          <div class="name-bold pd-15-px">
            <span class="product-name uppercase"><b>Tier: </b></span><br>
             <span class="product-name uppercase">{{ user.updated_at | formatDateTime }}</span> 
             <select
                      @change="changeTier($event.target.value, user.id)"
                      class="select-tier-style clickable label label-table label-info"
                    >
                      <option
                        class="option-style"
                        v-for="tier in ['0','1','2','3']"
                        :key="tier"
                        :value="tier"
                        :selected="tier == user.tier ? 'SELECTED' : false"
                      >{{tier | toTier}}</option>
                    </select> 
        </div>
        -->
        <div class="edit-product">

          <div class="pd-15-px fx-center 1fx-wrap">
            <i class="clickable fas fa-search" v-on:click="showModal(user.id)"></i>

          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<style scoped>
.width-100 {
  width: 100%;
}

.fs {
  font-size: .85rem;
}

td {
  text-align: center;
}

.pd-2-em {
  padding: 2em;
}

.container-infos {
  padding: 5px;
  overflow: scroll;
  width: 100%;
}

.container-infos::-webkit-scrollbar {
  visibility: hidden;
}

.option-style {
  background-color: #eee !important;
  margin: 6px 0;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 3px;
}

@media (max-width: 500px) {
  .select-tier-style {
    min-width: 110px;
  }

  .fs {
    font-size: 1rem;
  }

  .container-infos {
    height: 100%;
  }
}
</style>

<script>
import HeaderBuilder from "@/services/header-builder.js";
import User from "@/services/user.js";
import { apiBaseUrl } from "@/services/api.js";
import util from "@/services/util";
import axios from "axios";
import MobileDetect from 'mobile-detect'
import Loading from "vue-loading-overlay";

export default {
  name: "ManageUsers",
  props: {
    user_id: String
  },
  components: {
    Loading,
  },
  data: () => {
    return {
      isLoading: false,
      userList: [],
      modalUser: null,
      swap: null
    };
  },
  mounted() {
    this.loadManageUsers();
    if (this.user_id) {
      this.showModal(this.user_id);
    }
  },
  methods: {
    loadManageUsers: function() {
      this.isLoading = true;
      User.list()
        .then(selfUser => {
          this.userList = selfUser.filter(u => u.type != "ADMIN");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeTier: function(newTier, userId) {
      this.isLoading = true;
      axios
        .post(
          `${apiBaseUrl}/api/user/${userId}/tier`,
          {
            tier: newTier
          },
          {
            headers: HeaderBuilder.build()
          }
        )
        .then(
          response => {
            this.$notify({
              duration : 24000,
              type: "success",
              title: "Tier de Usuário Alterado",
              text: "Tier do Usuário Alterado com Sucesso!"
            });
            this.userList = this.userList.map(usr => {
              if (usr.id == userId) {
                usr.tier = newTier;
              }
              return usr;
            });
          },
          errorResponse => {
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro ao Alterar Tier de Usuário",
              text: util.stringifyAxiosError(errorResponse)
            });
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    manageUser: function(action, userId) {
      this.isLoading = true;
      const endpointAction = action == 1 ? "approve" : "deny";
      axios
        .put(`${apiBaseUrl}/api/user/${userId}/${endpointAction}`, null, {
          headers: HeaderBuilder.build()
        })
        .then(
          () => {
            if (action == 1) {
              this.$notify({
                duration : 24000,
                type: "success",
                title: "Usuário Aprovado",
                text: "Usuário Aprovado com Sucesso!"
              });
              this.changeLabelStatus(userId, true);
            } else {
              this.$notify({
                duration : 24000,
                type: "success",
                title: "Usuário Bloqueado",
                text: "Usuário Bloqueado com Sucesso!"
              });
              this.changeLabelStatus(userId, false);
            }
          },
          errorResponse => {
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro",
              text: util.stringifyAxiosError(errorResponse)
            });
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeLabelStatus: function(userId, approved) {
      this.userList.filter(u => u.id == userId)[0].approved = approved;
      this.$forceUpdate();
    },
    showModal: function(userId) {
      
      this.isLoading = true;
      User.get(userId)
        .then(selfUser => {
          this.swap = null;
          this.modalUser = selfUser;
          
          this.$forceUpdate();
        })
        .finally(() => {
          this.isLoading = false;
        });
 
	try {
  this.$modal.show("user-modal");
	} catch (error) {
		
		
	}

 
    },
    hideModal: function() {
      this.$modal.hide("user-modal");
      this.modalUser = {};
      this.swap = null;
      this.$forceUpdate();
    },
    swaper: function(flag) {
      if (flag) {
        this.swap = this.modalUser;
        this.modalUser = this.modalUser.update_proposal;
      } else {
        this.modalUser = this.swap;
        this.swap = null;
      }
    },
    cadastral: function(action) {
      this.isLoading = true;
      const endpointAction = action == true ? "approve" : "deny";
      axios
        .put(
          `${apiBaseUrl}/api/user/${this.modalUser.id}/update/${endpointAction}`,
          null,
          {
            headers: HeaderBuilder.build()
          }
        )
        .then(
          () => {
            if (action == true) {
              this.$notify({
                duration : 24000,
                type: "success",
                title: "Alteração Cadastral Aprovada",
                text: "Alteração Cadastral aprovada com Sucesso!"
              });
            } else {
              this.$notify({
                duration : 24000,
                type: "success",
                title: "Alteração Cadastral Negada",
                text: "Alteração Cadastral negada com Sucesso!"
              });
            }
            // TODO recarregar o user direito no front invés de chamar os GETs denovo...
            this.showModal(this.modalUser.id);
            this.loadManageUsers();
          },
          errorResponse => {
            this.$notify({
              duration : 24000,
              type: "error",
              title: "Erro na Alteração Cadastral",
              text: util.stringifyAxiosError(errorResponse)
            });
          }
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    mobile() {
      const md = new MobileDetect(window.navigator.userAgent)
      let mobile = md.mobile() == null
      
      return mobile
    }
  }
};
</script>